import React, { useEffect, useState } from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
  const { user, fetchUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      if (!user) {
        await fetchUser();
      }
      setIsLoading(false);
    };
    checkUser().then(_r => {});
  }, [user, fetchUser]);

  if (isLoading) {
    return <div className="d-flex justify-content-center align-items-center vh100 w-100">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>;
  }

  if (!user) {
    return <Navigate to="/login"/>;
  }

  return <>{children}</>;
};

export default PrivateRoute;
