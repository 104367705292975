import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useBreadcrumb } from "../../components/BreadcrumbContext";
import apiClient from "../../api/apiClient";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from '../../components/LoadingComponent';

interface Building {
  building_id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
}

const Buildings: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pending, setPending] = useState(true);
  const [data, setData] = useState<Building[]>([]);
  const [filteredData, setFilteredData] = useState<Building[]>([]);
  const [page, setPageSize] = useState<number>(searchParams.get('page') ? parseInt(searchParams.get('page') || '1') : 1);
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || '');
  const [selectedState, setSelectedState] = useState<string>(searchParams.get('state') || '');
  const [error, setError] = useState<string | null>(null);
  const { setItems } = useBreadcrumb();
  const navigate = useNavigate();

  const customStyles = {
    headCells: {
      style: {
        borderRadius: '0 0 0 0',
        color: 'var(--primary-dark-blue)',
      },
    },
    cells: {
      style: {
        color: 'var(--primary-dark-blue)',
      },
    },
  };

  // Breadcrumb
  useEffect(() => {
    setItems([
      { name: "Home", path: "/dashboard", isActive: false },
      { name: "Buildings", path: "/buildings", isActive: true },
    ]);
  }, [setItems]);

  // Fetch Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get<Building[]>("/buildings");
        if (Array.isArray(response.data)) {
          setData(response.data);
          setFilteredData(response.data);
          setPending(false);
        } else {
          console.error("Data is not an array", response.data);
          setError("Invalid data format");
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError("Failed to fetch data");
      }
    };

    fetchData();
  }, []);

  // Filter data based on search term and selected state
  useEffect(() => {
    const timeout = setTimeout(() => {
      const filtered = data.filter((building: Building) => {
        const matchesSearchTerm = searchTerm === '' ||
          (building.building_id && building.building_id.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (building.name && building.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (building.address && building.address.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (building.city && building.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (building.zip && building.zip.toLowerCase().includes(searchTerm.toLowerCase()));
        const matchesState = selectedState === '' || (building.state || 'N/A') === selectedState;
        return matchesSearchTerm && matchesState;
      });
      setFilteredData(filtered);
    }, 200);
    return () => clearTimeout(timeout);
  }, [searchTerm, selectedState, data]);

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const page = searchParams.get('page') ? parseInt(searchParams.get('page') || '1') : 1;
    const newParams = new URLSearchParams(searchParams.toString());
    newParams.set('page', String(page));
    newParams.set('state', e.target.value);
    setSearchParams(newParams);
    setSelectedState(e.target.value);
  };

  const uniqueStates = React.useMemo(() => {
    return Array.from(new Set(data.map((building) => building.state || "N/A")));
  }, [data]);

  const StateComponent = () => (
    <select value={selectedState} onChange={handleStateChange} className="state-select form-select form-select-sm w-auto">
      <option value="">All States</option>
      {uniqueStates.map((state) => (
        <option key={state} value={state}>{state}</option>
      ))}
    </select>
  );

  const handlePageChange = (page: number) => {
    const newParams = new URLSearchParams(searchParams.toString());
    const state = searchParams.get('state');
    newParams.set('page', String(page));
    newParams.set('state', String(state));
    setSearchParams(newParams);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between gap-3">
        <div className="d-flex align-items-center justify-content-start gap-3">
          <div className="custom-title fs-6">Building List</div>
          <div>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input form-control form-control-sm"
            />
          </div>
        </div>
        <div>
          <StateComponent />
        </div>
      </div>
    );
  }, [searchTerm, selectedState, uniqueStates]);

  if (error) {
    return <div>{error}</div>;
  }

  if (pending) {
    return <Loading />;
  }

  const columns = [
    {
      id: 'building_id',
      name: 'Building ID',
      selector: (row: Building) => row.building_id,
      sortable: true,
    },
    {
      id: "name",
      name: "Building Name",
      selector: (row: Building) => row.name,
      sortable: true,
    },
    {
      id: "address",
      name: "Address",
      selector: (row: Building) => row.address,
      sortable: true,
    },
    {
      id: "city",
      name: "City",
      selector: (row: Building) => row.city,
      sortable: true,
    },
    {
      id: "state",
      name: "State",
      selector: (row: Building) => row.state || 'N/A', // Show 'N/A' for empty states
      sortable: true,
    },
    {
      id: "zip",
      name: "Zip",
      selector: (row: Building) => row.zip,
      sortable: true,
    },
  ];

  const handleRowClicked = (row: Building) => {
    navigate(`/building/${row.building_id}`);
  };

  return (
    <div className="border rounded-3 overflow-hidden">
      <DataTable
        title={subHeaderComponentMemo}
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover
        pointerOnHover
        progressPending={pending}
        progressComponent={<Loading />}
        customStyles={customStyles}
        paginationDefaultPage={page}
        className="rounded-0"
        onRowClicked={handleRowClicked}
        onChangePage={handlePageChange}
      />
    </div>
  );
};

export default Buildings;
