const buildingsFormConfig = {
  title: "Edit Building",
  fields: [
    {
      name: "building_id",
      label: "Building ID",
      type: "text",
      placeholder: "Enter building ID",
      validation: { required: "Building ID is required" },
      className: "form-control-sm",
    },
    {
      name: "name",
      label: "Building Name",
      type: "text",
      placeholder: "Enter building name",
      validation: { required: "Building name is required" },
      className: "form-control-sm",
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      placeholder: "Enter address",
      validation: { required: "Address is required" },
      className: "form-control-sm",
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Enter city",
      validation: { required: "City is required" },
      className: "form-control-sm",
    },
    {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "Enter state",
      validation: { required: "State is required" },
      className: "form-control-sm",
    },
    {
      name: "zip",
      label: "Zip",
      type: "text",
      placeholder: "Enter zip",
      validation: { required: "zip is required" },
      className: "form-control-sm",
    },
  ]
};

export default buildingsFormConfig;
