import React, { createContext, useState, useContext, ReactNode, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './msalConfig';

interface AuthContextType {
  user: any;
  token: string | null;
  login: (email: string, password: string) => Promise<void>;
  register: (name: string, email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  fetchUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { instance } = useMsal();
  const [user, setUser] = useState<any>(JSON.parse(localStorage.getItem('user') || 'null'));
  const [token, setToken] = useState<string | null>(localStorage.getItem('backend_token'));

  const fetchUser = useCallback(async () => {
    const backendToken = localStorage.getItem('backend_token');
    if (backendToken && !user) {
      console.log('Fetching user data...');
      try {
        const userResponse = await axios.get(
          'https://merritt-api.roschweb.com/api/user',
          {
            headers: { Authorization: `Bearer ${backendToken}` },
          }
        );
        setUser(userResponse.data);
        localStorage.setItem('user', JSON.stringify(userResponse.data));
        console.log("User data retrieved:", userResponse.data);
      } catch (error) {
        console.error("Failed to fetch user:", error);
      }
    }
  }, [user]);

  useEffect(() => {
    console.log('AuthProvider mounted or token changed');
    fetchUser();
  }, [fetchUser]);

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post('https://merritt-api.roschweb.com/api/login', {
        email,
        password,
      });
      setToken(response.data.token);
      localStorage.setItem("backend_token", response.data.token);
      await fetchUser(); // Fetch user data after setting the backend token
    } catch (error) {
      throw new Error("Login failed");
    }
  };

  const register = async (name: string, email: string, password: string) => {
    try {
      await axios.post("https://merritt-api.roschweb.com/api/register", {
        name,
        email,
        password,
      });
      await login(email, password); // Automatically log in after registration
    } catch (error) {
      throw new Error("Registration failed");
    }
  };

  const logout = async () => {
    try {
      await axios.post(
        "https://merritt-api.roschweb.com/api/logout",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUser(null);
      setToken(null);
      localStorage.removeItem("backend_token");
      localStorage.removeItem("microsoft_token");
      localStorage.removeItem("user");
      await instance.logoutRedirect();
    } catch (error) {
      throw new Error("Logout failed");
    }
  };

  return (
    <AuthContext.Provider value={{ user, token, login, register, logout, fetchUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthContext;
