import React, { useEffect, useState } from "react";
import apiClient from "../../api/apiClient";
import { useBreadcrumb } from "../../components/BreadcrumbContext";
import Loading from '../../components/LoadingComponent';
import BudgetChart from '../../components/BudgetChartComponent';
import BuildingDetails from '../Buildings/BuildingDetails';

// Define the types for the data structure
interface BudgetYearData {
  [year: string]: number | undefined;
}

interface BuildingBudgetData {
  [buildingId: string]: BudgetYearData;
}

interface ChartData {
  name: string;
  budgets: BudgetYearData;
}

const Dashboard: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const [budgetData, setBudgetData] = useState<ChartData[]>([]);
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { setItems } = useBreadcrumb();

  useEffect(() => {
    setItems([
      { name: "Home", path: "/dashboard", isActive: false },
      { name: "Dashboard", path: "/dashboard", isActive: true },
    ]);
  }, [setItems]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get("/buildings");
        setData(response);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch data");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get<{ [key: string]: BudgetYearData }>('/budgets');
        console.log("Budget data fetched:", response);
        if (response.data) {
          const formattedData: ChartData[] = Object.keys(response.data).map(buildingId => ({
            name: buildingId,
            budgets: response.data[buildingId]  // Assigning directly to budgets
          }));
          setBudgetData(formattedData);
          setPending(false);
        } else {
          console.error("Data format is invalid", response.data);
          setError("Invalid data format");
        }
      } catch (err) {
        console.error("Error fetching budget data:", err);
        setError("Failed to fetch data");
        setPending(false);
      }
    };

    fetchData().then(_r => {});
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <Loading />;
  }

  return (
    <div className="container-lg px-4">
      <div className="row g-4 mb-4">
        <div className="col-sm-6 col-xl-3">
          <div className="card text-white bg-primary-gradient">
            <div className="card-body pb-0 d-flex justify-content-between align-items-start">
              <div>
                <div className="fs-4 fw-semibold">
                  26K
                  <span className="fs-6 fw-normal">
                    (-12.4% <i className="fa-solid fa-arrow-down-to-line"></i>)
                  </span>
                </div>
                <div>Users</div>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-transparent text-white p-0"
                  type="button"
                  data-coreui-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa-regular fa-ellipsis-stroke-vertical"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div>
            </div>
            <div
              className="c-chart-wrapper mt-3 mx-3"
              style={{ height: "70px" }}
            >
              {/*<canvas className="chart" id="card-chart1" height="70"></canvas>*/}
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-xl-3">
          <div className="card text-white bg-info-gradient">
            <div className="card-body pb-0 d-flex justify-content-between align-items-start">
              <div>
                <div className="fs-4 fw-semibold">
                  $6.200{" "}
                  <span className="fs-6 fw-normal">
                    (40.9%<i className="fa-solid fa-arrow-down-to-line"></i>)
                  </span>
                </div>
                <div>Income</div>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-transparent text-white p-0"
                  type="button"
                  data-coreui-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa-regular fa-ellipsis-stroke-vertical"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div>
            </div>
            <div
              className="c-chart-wrapper mt-3 mx-3"
              style={{ height: "70px" }}
            >
              {/*<canvas className="chart" id="card-chart2" height="70"></canvas>*/}
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-xl-3">
          <div className="card text-white bg-warning-gradient">
            <div className="card-body pb-0 d-flex justify-content-between align-items-start">
              <div>
                <div className="fs-4 fw-semibold">
                  2.49%
                  <span className="fs-6 fw-normal">
                    (84.7%<i className="fa-solid fa-arrow-down-to-line"></i>)
                  </span>
                </div>
                <div>Conversion Rate</div>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-transparent text-white p-0"
                  type="button"
                  data-coreui-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa-regular fa-ellipsis-stroke-vertical"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div>
            </div>
            <div className="c-chart-wrapper mt-3" style={{ height: "70px" }}>
              {/*<canvas className="chart" id="card-chart3" height="70"></canvas>*/}
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-xl-3">
          <div className="card text-white bg-danger-gradient">
            <div className="card-body pb-0 d-flex justify-content-between align-items-start">
              <div>
                <div className="fs-4 fw-semibold">
                  44K
                  <span className="fs-6 fw-normal">
                    (-23.6%<i className="fa-solid fa-arrow-down-to-line"></i>)
                  </span>
                </div>
                <div>Sessions</div>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-transparent text-white p-0"
                  type="button"
                  data-coreui-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-arrow-down-to-line"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div>
            </div>
            <div
              className="c-chart-wrapper mt-3 mx-3"
              style={{ height: "70px" }}
            >
              {/*<canvas className="chart" id="card-chart4" height="70"></canvas>*/}
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="card-title mb-0">Budgets by Building ID</h4>
              <div className="small text-body-secondary">
                January - July 2023
              </div>
            </div>
            <div
              className="btn-toolbar d-none d-md-block"
              role="toolbar"
              aria-label="Toolbar with buttons"
            >
              <div
                className="btn-group btn-group-toggle mx-3"
                data-coreui-toggle="buttons"
              >
                <input
                  className="btn-check"
                  id="option1"
                  type="radio"
                  name="options"
                />
                <label className="btn btn-outline-secondary"> Day</label>
                <input
                  className="btn-check"
                  id="option2"
                  type="radio"
                  name="options"
                />
                <label className="btn btn-outline-secondary active">
                  {" "}
                  Month
                </label>
                <input
                  className="btn-check"
                  id="option3"
                  type="radio"
                  name="options"
                />
                <label className="btn btn-outline-secondary"> Year</label>
              </div>
              <button className="btn btn-primary" type="button">
                <i className="fa-solid fa-download"></i>
              </button>
            </div>
          </div>
          <div className="c-chart-wrapper mt-4" style={{ height: "400px" }}>
            <BudgetChart data={budgetData} />
          </div>
        </div>
      </div>

      <div className="row g-4 mb-4">
        <div className="col-sm-6 col-lg-4">
          <div className="card">
            <div className="card-header position-relative d-flex justify-content-center align-items-center">
              <i className="fa-brands fa-facebook"></i>
              <div className="chart-wrapper position-absolute top-0 start-0 w-100 h-100">
                {/*<canvas id="social-box-chart-1" height="90"></canvas>*/}
              </div>
            </div>
            <div className="card-body row text-center">
              <div className="col">
                <div className="fs-5 fw-semibold">89k</div>
                <div className="text-uppercase text-body-secondary small">
                  friends
                </div>
              </div>
              <div className="vr"></div>
              <div className="col">
                <div className="fs-5 fw-semibold">459</div>
                <div className="text-uppercase text-body-secondary small">
                  feeds
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-4">
          <div className="card">
            <div className="card-header position-relative d-flex justify-content-center align-items-center">
              <i className="fa-brands fa-twitter"></i>
              <div className="chart-wrapper position-absolute top-0 start-0 w-100 h-100">
                {/*<canvas id="social-box-chart-2" height="90"></canvas>*/}
              </div>
            </div>
            <div className="card-body row text-center">
              <div className="col">
                <div className="fs-5 fw-semibold">973k</div>
                <div className="text-uppercase text-body-secondary small">
                  followers
                </div>
              </div>
              <div className="vr"></div>
              <div className="col">
                <div className="fs-5 fw-semibold">1.792</div>
                <div className="text-uppercase text-body-secondary small">
                  tweets
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-4">
          <div className="card">
            <div className="card-header position-relative d-flex justify-content-center align-items-center">
              <i className="fa-brands fa-linkedin"></i>
              <div className="chart-wrapper position-absolute top-0 start-0 w-100 h-100">
                {/*<canvas id="social-box-chart-3" height="90"></canvas>*/}
              </div>
            </div>
            <div className="card-body row text-center">
              <div className="col">
                <div className="fs-5 fw-semibold">500+</div>
                <div className="text-uppercase text-body-secondary small">
                  contacts
                </div>
              </div>
              <div className="vr"></div>
              <div className="col">
                <div className="fs-5 fw-semibold">292</div>
                <div className="text-uppercase text-body-secondary small">
                  feeds
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-header">Traffic &amp; Sales</div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="border-start border-start-4 border-start-info px-3 mb-3">
                        <div className="small text-body-secondary text-truncate">
                          New Clients
                        </div>
                        <div className="fs-5 fw-semibold">9.123</div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="border-start border-start-4 border-start-danger px-3 mb-3">
                        <div className="small text-body-secondary text-truncate">
                          Recurring Clients
                        </div>
                        <div className="fs-5 fw-semibold">22.643</div>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-0" />
                  <div className="progress-group mb-4">
                    <div className="progress-group-prepend">
                      <span className="text-body-secondary small">Monday</span>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "34%" }}
                        ></div>
                      </div>
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "78%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group mb-4">
                    <div className="progress-group-prepend">
                      <span className="text-body-secondary small">Tuesday</span>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "56%" }}
                        ></div>
                      </div>
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "94%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group mb-4">
                    <div className="progress-group-prepend">
                      <span className="text-body-secondary small">
                        Wednesday
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "12%" }}
                        ></div>
                      </div>
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "67%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group mb-4">
                    <div className="progress-group-prepend">
                      <span className="text-body-secondary small">
                        Thursday
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "43%" }}
                        ></div>
                      </div>
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "91%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group mb-4">
                    <div className="progress-group-prepend">
                      <span className="text-body-secondary small">Friday</span>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "22%" }}
                        ></div>
                      </div>
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "73%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group mb-4">
                    <div className="progress-group-prepend">
                      <span className="text-body-secondary small">
                        Saturday
                      </span>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "53%" }}
                        ></div>
                      </div>
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "82%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group mb-4">
                    <div className="progress-group-prepend">
                      <span className="text-body-secondary small">Sunday</span>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "9%" }}
                        ></div>
                      </div>
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "69%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="border-start border-start-4 border-start-warning px-3 mb-3">
                        <div className="small text-body-secondary text-truncate">
                          Pageviews
                        </div>
                        <div className="fs-5 fw-semibold">78.623</div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="border-start border-start-4 border-start-success px-3 mb-3">
                        <div className="small text-body-secondary text-truncate">
                          Organic
                        </div>
                        <div className="fs-5 fw-semibold">49.123</div>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-0" />
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <i className="fas fa-user"></i>
                      <div>Male</div>
                      <div className="ms-auto fw-semibold">43%</div>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: "43%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group mb-5">
                    <div className="progress-group-header">
                      <i className="far fa-user-female"></i>
                      <div>Female</div>
                      <div className="ms-auto fw-semibold">37%</div>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: "43%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <i className="fa-brands fa-google"></i>
                      <div>Organic Search</div>
                      <div className="ms-auto fw-semibold me-2">191.235</div>
                      <div className="text-body-secondary small">(56%)</div>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "56%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <i className="fa-brands fa-facebook"></i>
                      <div>Facebook</div>
                      <div className="ms-auto fw-semibold me-2">51.223</div>
                      <div className="text-body-secondary small">(15%)</div>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "15%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <i className="fa-brands fa-twitter"></i>
                      <div>Twitter</div>
                      <div className="ms-auto fw-semibold me-2">37.564</div>
                      <div className="text-body-secondary small">(11%)</div>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "11%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-group">
                    <div className="progress-group-header">
                      <i className="fa-brands fa-linkedin"></i>
                      <div>LinkedIn</div>
                      <div className="ms-auto fw-semibold me-2">27.319</div>
                      <div className="text-body-secondary small">(8%)</div>
                    </div>
                    <div className="progress-group-bars">
                      <div className="progress progress-thin">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "8%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table border mb-0">
                  <thead className="fw-semibold text-nowrap">
                    <tr className="align-middle">
                      <th className="bg-body-secondary text-center">
                        <i className="far fa-people"></i>
                      </th>
                      <th className="bg-body-secondary">User</th>
                      <th className="bg-body-secondary text-center">Country</th>
                      <th className="bg-body-secondary">Usage</th>
                      <th className="bg-body-secondary text-center">
                        Payment Method
                      </th>
                      <th className="bg-body-secondary">Activity</th>
                      <th className="bg-body-secondary"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="align-middle">
                      <td className="text-center">
                        <div className="avatar avatar-md">
                          <img
                            className="avatar-img"
                            src="assets/img/avatars/1.jpg"
                            alt="user@email.com"
                          />
                          <span className="avatar-status bg-success"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text-nowrap">Yiorgos Avraamu</div>
                        <div className="small text-body-secondary text-nowrap">
                          <span>New</span> | Registered: Jan 1, 2023
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-duotone fa-flag"></i>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="fw-semibold">50%</div>
                          <div className="text-nowrap small text-body-secondary ms-3">
                            Jun 11, 2023 - Jul 10, 2023
                          </div>
                        </div>
                        <div className="progress progress-thin">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{ width: "50%" }}
                          ></div>
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-brands fa-cc-mastercard"></i>
                      </td>
                      <td>
                        <div className="small text-body-secondary">
                          Last login
                        </div>
                        <div className="fw-semibold text-nowrap">
                          10 sec ago
                        </div>
                      </td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-transparent p-0"
                            type="button"
                            data-coreui-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fa-regular fa-ellipsis-stroke-vertical"></i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a className="dropdown-item" href="#">
                              Info
                            </a>
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item text-danger" href="#">
                              Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="text-center">
                        <div className="avatar avatar-md">
                          <img
                            className="avatar-img"
                            src="assets/img/avatars/2.jpg"
                            alt="user@email.com"
                          />
                          <span className="avatar-status bg-danger"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text-nowrap">Avram Tarasios</div>
                        <div className="small text-body-secondary text-nowrap">
                          <span>Recurring</span> | Registered: Jan 1, 2023
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-duotone fa-flag-usa"></i>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="fw-semibold">10%</div>
                          <div className="text-nowrap small text-body-secondary ms-3">
                            Jun 11, 2023 - Jul 10, 2023
                          </div>
                        </div>
                        <div className="progress progress-thin">
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: "10%" }}
                          ></div>
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-brands fa-cc-visa"></i>
                      </td>
                      <td>
                        <div className="small text-body-secondary">
                          Last login
                        </div>
                        <div className="fw-semibold text-nowrap">
                          5 minutes ago
                        </div>
                      </td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-transparent p-0"
                            type="button"
                            data-coreui-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fa-regular fa-ellipsis-stroke-vertical"></i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a className="dropdown-item" href="#">
                              Info
                            </a>
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item text-danger" href="#">
                              Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="text-center">
                        <div className="avatar avatar-md">
                          <img
                            className="avatar-img"
                            src="assets/img/avatars/3.jpg"
                            alt="user@email.com"
                          />
                          <span className="avatar-status bg-warning"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text-nowrap">Quintin Ed</div>
                        <div className="small text-body-secondary text-nowrap">
                          <span>New</span> | Registered: Jan 1, 2023
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-duotone fa-flag-usa"></i>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="fw-semibold">74%</div>
                          <div className="text-nowrap small text-body-secondary ms-3">
                            Jun 11, 2023 - Jul 10, 2023
                          </div>
                        </div>
                        <div className="progress progress-thin">
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            style={{ width: "74%" }}
                          ></div>
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-brands fa-cc-stripe"></i>
                      </td>
                      <td>
                        <div className="small text-body-secondary">
                          Last login
                        </div>
                        <div className="fw-semibold text-nowrap">
                          1 hour ago
                        </div>
                      </td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-transparent p-0"
                            type="button"
                            data-coreui-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fa-regular fa-ellipsis-stroke-vertical"></i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a className="dropdown-item" href="#">
                              Info
                            </a>
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item text-danger" href="#">
                              Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="text-center">
                        <div className="avatar avatar-md">
                          <img
                            className="avatar-img"
                            src="assets/img/avatars/4.jpg"
                            alt="user@email.com"
                          />
                          <span className="avatar-status bg-secondary"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text-nowrap">Enéas Kwadwo</div>
                        <div className="small text-body-secondary text-nowrap">
                          <span>New</span> | Registered: Jan 1, 2023
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-duotone fa-flag-usa"></i>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="fw-semibold">98%</div>
                          <div className="text-nowrap small text-body-secondary ms-3">
                            Jun 11, 2023 - Jul 10, 2023
                          </div>
                        </div>
                        <div className="progress progress-thin">
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            style={{ width: "98%" }}
                          ></div>
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-brands fa-paypal"></i>
                      </td>
                      <td>
                        <div className="small text-body-secondary">
                          Last login
                        </div>
                        <div className="fw-semibold text-nowrap">
                          Last month
                        </div>
                      </td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-transparent p-0"
                            type="button"
                            data-coreui-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fa-regular fa-ellipsis-stroke-vertical"></i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a className="dropdown-item" href="#">
                              Info
                            </a>
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item text-danger" href="#">
                              Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="text-center">
                        <div className="avatar avatar-md">
                          <img
                            className="avatar-img"
                            src="assets/img/avatars/5.jpg"
                            alt="user@email.com"
                          />
                          <span className="avatar-status bg-success"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text-nowrap">Agapetus Tadeáš</div>
                        <div className="small text-body-secondary text-nowrap">
                          <span>New</span> | Registered: Jan 1, 2023
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-duotone fa-flag-usa"></i>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="fw-semibold">22%</div>
                          <div className="text-nowrap small text-body-secondary ms-3">
                            Jun 11, 2023 - Jul 10, 2023
                          </div>
                        </div>
                        <div className="progress progress-thin">
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: "22%" }}
                          ></div>
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-brands fa-apple-pay"></i>
                      </td>
                      <td>
                        <div className="small text-body-secondary">
                          Last login
                        </div>
                        <div className="fw-semibold text-nowrap">Last week</div>
                      </td>
                      <td>
                        <div className="dropdown dropup">
                          <button
                            className="btn btn-transparent p-0"
                            type="button"
                            data-coreui-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fa-regular fa-ellipsis-stroke-vertical"></i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a className="dropdown-item" href="#">
                              Info
                            </a>
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item text-danger" href="#">
                              Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="text-center">
                        <div className="avatar avatar-md">
                          <img
                            className="avatar-img"
                            src="assets/img/avatars/6.jpg"
                            alt="user@email.com"
                          />
                          <span className="avatar-status bg-danger"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text-nowrap">Friderik Dávid</div>
                        <div className="small text-body-secondary text-nowrap">
                          <span>New</span> | Registered: Jan 1, 2023
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-duotone fa-flag-usa"></i>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="fw-semibold">43%</div>
                          <div className="text-nowrap small text-body-secondary ms-3">
                            Jun 11, 2023 - Jul 10, 2023
                          </div>
                        </div>
                        <div className="progress progress-thin">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{ width: "43%" }}
                          ></div>
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa-brands fa-amex"></i>
                      </td>
                      <td>
                        <div className="small text-body-secondary">
                          Last login
                        </div>
                        <div className="fw-semibold text-nowrap">Yesterday</div>
                      </td>
                      <td>
                        <div className="dropdown dropup">
                          <button
                            className="btn btn-transparent p-0"
                            type="button"
                            data-coreui-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fa-regular fa-ellipsis-stroke-vertical"></i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a className="dropdown-item" href="#">
                              Info
                            </a>
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item text-danger" href="#">
                              Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
