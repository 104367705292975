// IdleTimerComponent.tsx
import React, { useRef, useState } from 'react';
import { useIdleTimer, IIdleTimer } from "react-idle-timer";
import {useLocation, useNavigate} from 'react-router-dom';
import { Toast } from 'react-bootstrap';
import { useToast } from '../contexts/ToastContext';

const IdleTimerComponent: React.FC = () => {
  const idleTimerRef = useRef<IIdleTimer>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { triggerToast } = useToast();

  const onIdle = () => {
    // Check if the current route is not the login page
    if (location.pathname !== "/login") {
      triggerToast("You have been logged out due to inactivity.");
      // For example, clearing user data and redirecting to the login page
      localStorage.clear();
      navigate("/login");
    }
  };

  const handleOnActive = () => {
    // User is active, you can reset any necessary timers or actions here
  };

  const handleOnAction = () => {
    // User performed some action, you can reset any necessary timers or actions here
  };

  useIdleTimer({
    ref: idleTimerRef,
    timeout: 1000 * 60 * 15, // 15 minutes
    onIdle: onIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return <div ref={idleTimerRef as any} />;
};

export default IdleTimerComponent;
