import React, { useState } from 'react';
import CSVUploadForm from '../../components/UnitUploadForm'; // Adjust the import path as necessary
import { Card } from 'react-bootstrap';

const Tools: React.FC = () => {
  const [importStats, setImportStats] = useState({ imported: 0 });

  const handleUploadComplete = (data: { imported: number }) => {
    setImportStats(data);
  };

  return (
    <div>
      <h1>Tools</h1>
      <Card className="p-2">
        <CSVUploadForm onUploadComplete={handleUploadComplete} />
        {importStats.imported ? (
          <div>
            <p>Imported Units: {importStats.imported}</p>
          </div>
        ) : null}
      </Card>
    </div>
  );
};

export default Tools;

