import React, { useState } from 'react';
import apiClient from '../api/apiClient';
import { Button } from 'react-bootstrap';

interface CSVUploadFormProps {
  onUploadComplete: (data: { imported: number; updated: number }) => void;
}

const CSVUploadForm: React.FC<CSVUploadFormProps> = ({ onUploadComplete }) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setFile(files[0]);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await apiClient.post('/import-units', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Success:', response.data);
        alert('File uploaded successfully!');
        onUploadComplete(response.data); // Call the callback with the response data
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3 input-group">
        <input className="form-control" type="file" id="formFile"
               onChange={handleFileChange} accept=".csv" required/>
        <Button variant="outline-secondary btn-sm" type="submit">
          <i className="fas fa-upload"></i>
        </Button>
      </div>
    </form>
  );
};

export default CSVUploadForm;
