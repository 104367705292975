import React from 'react';
import { Card, Col } from 'react-bootstrap';

interface BudgetCardProps {
  budget: {
    budget_year: string;
    amount: string;
    description: string;
    target_period: string;
  };
  iconClass?: string;
}

const BudgetCard: React.FC<BudgetCardProps> = ({ budget }) => {
  const currentYear = new Date().getFullYear();
  const budgetYear = parseInt(budget.budget_year);
  const isCurrentYear = budgetYear === currentYear;
  const isPreviousYear = budgetYear === currentYear - 1;
  const isNextYear = budgetYear === currentYear + 1;

  const getTitle = () => {
    if (isCurrentYear) {
      return `Current (${budget.target_period})`;
    } else if (isPreviousYear) {
      return `Previous Budget (${budget.target_period || budget.budget_year})`;
    } else if (isNextYear) {
      return `Next Budget (${budget.budget_year})`;
    } else {
      return `${budget.budget_year} (${budget.target_period || budget.budget_year})`;
    }
  };

  return (
    <Col lg={3} className="mb-3">
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <span>{getTitle()}</span>
            <i className="fas fa-dollar-sign text-primary"></i>
          </div>
          <h5 className="text-primary mb-0">
            ${budget?.amount}
          </h5>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default BudgetCard;
