import React, {createContext, ReactNode, useContext, useState} from 'react';
import Header from '../../layout/Header/Header';
import Footer from '../../layout/Footer/Footer';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { BreadcrumbProvider } from '../../components/BreadcrumbContext';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

interface BreadcrumbItem {
  name: string;
  path: string;
  isActive: boolean;
}

interface BreadcrumbContextType {
  items: BreadcrumbItem[];
  setItems: (items: BreadcrumbItem[]) => void;
}

interface MainLayoutProps {
  children: React.ReactNode;
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export const useBreadcrumb = () => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
  }
  return context;
};

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSidebarLinkClick = () => {
    if (window.innerWidth <= 768) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <BreadcrumbProvider>
    <div className={`w-100 overflow-hidden bg-light ${isOpen ? 'body-pd ' : ''}`}>
      <Header isOpen={isOpen} handleClick={handleClick} />
      <Sidebar isOpen={isOpen} handleClick={handleClick}
               handleSidebarLinkClick={handleSidebarLinkClick}/>
      <main className="w-100 bg-light d-flex flex-column justify-content-between">
        <Breadcrumb />
        <div className="flex-grow-1 overflow-auto p-3">{children}</div>
        <Footer />
      </main>
    </div>
    </BreadcrumbProvider>
  );
};

export default MainLayout;
