import React from "react";
import { Link } from "react-router-dom";

interface SidebarProps {
  isOpen: boolean;
  handleClick: () => void;
  handleSidebarLinkClick: () => void;
}

const sidebarData = [
  {
    path: "/dashboard",
    icon: "far fa-grid-2 nav_icon",
    name: "Dashboard",
  },
  {
    path: "/buildings",
    icon: "far fa-buildings nav_icon",
    name: "Buildings",
  },
  {
    path: "/tenants",
    icon: "fas fa-person-shelter nav_icon",
    name: "Tenants",
  },
  {
    path: "/pmsheet",
    icon: "far fa-tools nav_icon",
    name: "PM Sheet",
  },
  {
    path: "/tools",
    icon: "far fa-folder-gear nav_icon",
    name: "Tools",
  },
];

const Sidebar: React.FC<SidebarProps> = ({ isOpen, handleClick, handleSidebarLinkClick }) => {
  return (
    <div
      className={`sidebar p-3 d-flex flex-column justify-content-between ${
        isOpen ? "show-sidebar" : ""
      }`}
    >
      <nav className="nav">
        <div className="overflow-hidden w-100">
          <div className="d-flex align-items-top justify-content-between w-100">
            <Link to="/" className="nav_logo mb-3 d-block w-75">
              <img src="/images/logo-header.webp" alt="logo" />
            </Link>
            <a href="#" className="nav_close" onClick={handleClick}>
              <i className="fas fa-bars-staggered text-white fa-2x"></i>
            </a>
          </div>
          <div className="nav_list d-flex flex-column gap-2 justify-content-start flex-grow-1 pt-3">
            {sidebarData.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className={`nav_link ${item.path === window.location.pathname ? "active" : ""}`}
                onClick={handleSidebarLinkClick}
              >
                <i className={item.icon}></i>
                <span className="nav_name">{item.name}</span>
              </Link>
            ))}
          </div>
        </div>
      </nav>
      <div className="nav">
        <Link to="/logout" className="nav_link">
          <i className="fas fa-left-from-bracket nav_icon"></i>
          <span className="nav_name">SignOut</span>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
