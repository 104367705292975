import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { APIProvider, Map, Marker, InfoWindow, useMap, useMarkerRef, MapControl, ControlPosition } from '@vis.gl/react-google-maps';

interface GoogleMapsComponentProps {
  address: string;
  building_name?: string;
}

const MapContainer: React.FC<GoogleMapsComponentProps> = ({ address, building_name }) => {
  const map = useMap();
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(20);
  const apiKey = 'AIzaSyCCDzUWOVQpFuX2mJ5HeaNczoeNl0xwosM';
  const [markerRef, marker] = useMarkerRef();
  const [infoWindowShown, setInfoWindowShown] = useState(true);

  const handleMarkerClick = useCallback(
    () => setInfoWindowShown(isShown => !isShown),
    []
  );

  useEffect(() => {
    if (address) {
      axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          address,
          key: apiKey
        }
      }).then(response => {
        if (response.data.status === 'OK') {
          const loc = response.data.results[0].geometry.location;
          setLocation(loc);
          map?.setCenter(loc);
        } else {
          console.error('Geocoding failed:', response.data.status);
        }
      }).catch(error => {
        console.error('Error fetching geocode:', error);
      });
    }
  }, [address, map, apiKey]);

  return (
    <Map
      style={{ width: "100%", height: "100%" }}
      defaultCenter={location}
      defaultZoom={zoom}
      gestureHandling="greedy"
      disableDefaultUI
      mapTypeId="satellite"
    >
      <Marker position={location} ref={markerRef} onClick={handleMarkerClick} />
      {infoWindowShown && (
        <InfoWindow anchor={marker}
                    headerContent={<h6 className="text-dark">{address}</h6>}>
        </InfoWindow>
      )}
    </Map>
  );
};

const GoogleMapsComponent: React.FC<GoogleMapsComponentProps> = (props) => {
  return (
    <APIProvider apiKey={'AIzaSyCCDzUWOVQpFuX2mJ5HeaNczoeNl0xwosM'}>
      <MapContainer {...props} />
    </APIProvider>
  );
};

export default GoogleMapsComponent;
