import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface BudgetYearData {
  [year: string]: number | undefined;  // Allow for undefined budget data
}

interface ChartData {
  name: string;
  budgets?: BudgetYearData;  // Optional budgets object
}

interface BudgetChartProps {
  data: ChartData[];
}

const BudgetChart: React.FC<BudgetChartProps> = ({ data }) => {
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        type: 'linear' as const,
      },
      x: {
        type: 'category' as const,
        labels: data.map(item => item.name),
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true
      },
      tooltip: {
        enabled: true
      }
    }
  };

  // Function to retrieve budget data, ensuring a number is always returned
  const getBudgetData = (item: ChartData, year: string): number => {
    return item.budgets?.[year] ?? 0;  // Nullish coalescing operator to default to 0 if undefined
  };

  const chartData = {
    labels: data.map(item => item.name),
    datasets: [
      {
        label: "2023 Budget",
        data: data.map(item => getBudgetData(item, "2023")),
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        tension: 0.1,
      },
      {
        label: "2024 Budget",
        data: data.map(item => getBudgetData(item, "2024")),
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        tension: 0.1,
      },
      {
        label: "2025 Budget",
        data: data.map(item => getBudgetData(item, "2025")),
        borderColor: "rgba(255, 206, 86, 1)",
        backgroundColor: "rgba(255, 206, 86, 0.5)",
        tension: 0.1,
      },
    ],
  };

  return (
    <div style={{ height: '400px' }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default BudgetChart;
