import { useEffect } from 'react';

const usePageClasses = (bodyClasses: string, rootClasses: string) => {
  useEffect(() => {
    const bodyClassList = bodyClasses.split(' ');
    const rootClassList = rootClasses.split(' ');

    // Add classes to body
    bodyClassList.forEach((cls) => document.body.classList.add(cls));

    // Add classes to root element
    const root = document.getElementById('root');
    if (root) {
      rootClassList.forEach((cls) => root.classList.add(cls));
    }

    // Cleanup function to remove the classes when the component unmounts or updates
    return () => {
      bodyClassList.forEach((cls) => document.body.classList.remove(cls));
      if (root) {
        rootClassList.forEach((cls) => root.classList.remove(cls));
      }
    };
  }, [bodyClasses, rootClasses]);
};

export default usePageClasses;
