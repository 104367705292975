import React, { useEffect, useRef, useState } from 'react';
import { useBreadcrumb } from "../../components/BreadcrumbContext";
import { useParams } from "react-router-dom";
import apiClient from "../../api/apiClient";
import { Button, Card, Col, Modal, Nav, Offcanvas, Row, Tab } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import Tenants from "../../pages/Tenants/Tenants";
import DynamicForm from "../../components/DynamicForm";
import buildingsFormConfig from "./BuildingsFormConfig";
import unitsFormConfig from '../Units/UnitsFormConfig';
import GoogleMapsComponent from "../../components/GoogleMapsComponent";
import BudgetCard from '../../components/BudgetCard';

interface Budget {
  id: number;
  building_id: string;
  budget_year: string;
  amount: string;
  description: string;
  target_period: string;
  icon_class?: string;
}

interface BuildingDetails {
  building_id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  budgets: Budget[];
  property_manager: {
    first_name: string;
    last_name: string;
  };
  technician: {
    first_name: string;
    last_name: string;
  };
}

interface Unit {
  id: number;
  asset_code: string;
  building_id: string;
  location: string;
  manufacturer: string;
  model_number: string;
  serial_number: string;
  tenant_code: string;
  description: string;
  replaced: string;
  belts: string;
  filters: string;
  voltage: string;
  contract: string;
  notes: string;
}

const BuildingDetails: React.FC = () => {
  const { setItems } = useBreadcrumb();
  const { id } = useParams<{ id: string }>();
  const [buildingData, setBuildingData] = useState<BuildingDetails | null>(
    null
  );
  const [buildingUnits, setBuildingUnits] = useState<Unit[]>([]);
  const [filteredUnitData, setFilteredData] = useState<Unit[]>([]);
  const [searchTermUnits, setSearchTermUnits] = useState("");
  const [pending, setPending] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [show, setShow] = useState(false);
  const [activeKey, setActiveKey] = useState("units");
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);

  const customStyles = {
    headCells: {
      style: {
        borderRadius: "0 0 0 0",
        color: "var(--primary-dark-blue)",
      },
    },
    cells: {
      style: {
        color: "var(--primary-dark-blue)",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row: { description: string }) => row.description.includes('DNU'),
      style: {
        backgroundColor: "#ffedf2",
        color: "#fcdce5 !important",
        className: "text-danger",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  useEffect(() => {
    setItems([
      { name: "Home", path: "/dashboard", isActive: false },
      { name: "Buildings", path: "/buildings/", isActive: false },
      { name: `${id}`, path: `${id}`, isActive: true },
    ]);
  }, [setItems]);

  const unitsSubHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between gap-3">
        <div className="d-flex align-items-center justify-content-start gap-3">
          <div className="custom-title fs-6">Equipment List</div>
          <div>
            <input
              type="text"
              placeholder="Search..."
              value={searchTermUnits}
              onChange={(e) => setSearchTermUnits(e.target.value)}
              className="search-input form-control form-control-sm"
            />
          </div>
        </div>
        <div></div>
      </div>
    );
  }, [searchTermUnits]);

  const CustomLoader = () => (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="p-4">
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    fetchData().then();
  }, [id]);

  useEffect(() => {
    fetchUnits().then();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const filteredUnitData = buildingUnits.filter((unit: Unit) => {
        return (
          searchTermUnits === "" ||
          unit.model_number
            .toLowerCase()
            .includes(searchTermUnits.toLowerCase()) ||
          unit.manufacturer
            .toLowerCase()
            .includes(searchTermUnits.toLowerCase()) ||
          unit.serial_number
            .toLowerCase()
            .includes(searchTermUnits.toLowerCase()) ||
          unit.filters.toLowerCase().includes(searchTermUnits.toLowerCase()) ||
          unit.notes.toLowerCase().includes(searchTermUnits.toLowerCase())
        );
      });
      setFilteredData(filteredUnitData);
    }, 200);
    return () => clearTimeout(timeout);
  }, [searchTermUnits, buildingUnits]);

  const fetchUnits = async () => {
    try {
      const response = await apiClient.get<Unit[]>(
        "/building/" + id + "/units"
      );
      console.log("response", response);
      if (Array.isArray(response.data)) {
        setBuildingUnits(response.data);
        setPending(false);
      } else {
        console.error("Data is not an array", response.data);
        setError("Invalid data format");
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data");
      setPending(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await apiClient.get<BuildingDetails>(
        "/buildings/" + id
      );
      console.log("response", response);
      if (response.data) {
        setBuildingData(response.data);
        setPending(false);
      } else {
        console.error("Data is not an array", response.data);
        setError("Invalid data format");
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data");
    }
  };

  const ExpandedComponent = ({ data }: { data: Unit }) => (
    <div className="ps-2 pe-3 py-3 bg-primary bg-opacity-10">
      <div className="d-flex justify-content-start align-items-start">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Button
              variant="link-dark"
              size="sm"
              onClick={() => handleUnitEdit(data)}
            >
              <i className="fas fa-eye"></i>
            </Button>
          </div>
        </div>
        <div>
          <div className="d-flex flex-column justify-content-start gap-2 align-items-start mb-2">
            <span className="px-2">
              Description: <span className="fw-medium">{data.description}</span>
            </span>
            <span className="px-2">
              Voltage: <span className="fw-medium">{data.voltage}</span>
            </span>
            <span className="px-2">
              Filters: <span className="fw-medium">{data.filters}</span>
            </span>
            <span className="px-2">
              Belts: <span className="fw-medium">{data.belts}</span>
            </span>
            <span className="px-2">
              Notes: <span className="fw-medium">{data.notes}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const openTabAndScroll = (tabKey: React.SetStateAction<string>) => {
    setActiveKey(tabKey); // Change the active tab
    setTimeout(() => {
      scrollToRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const unitColumns = [
    {
      id: "id",
      name: "ID",
      selector: (row: Unit) => row.id,
      omit: true,
    },
    {
      id: "location",
      name: "Location",
      selector: (row: Unit) => row.location,
      sortable: true,
    },
    {
      id: "asset_code",
      name: "Asset code",
      selector: (row: Unit) => row.asset_code,
      sortable: true,
    },
    {
      id: "manufacturer",
      name: "Manufacturer",
      selector: (row: Unit) => row.manufacturer,
      sortable: true,
    },
    {
      id: "model",
      name: "Model",
      selector: (row: Unit) => row.model_number,
      sortable: true,
    },
    {
      id: "serial",
      name: "Serial Number",
      selector: (row: Unit) => row.serial_number,
      sortable: true,
    },
    {
      id: "tcode",
      name: "Tenant Code",
      selector: (row: Unit) => row.tenant_code,
      sortable: true,
    },
    {
      id: "description",
      name: "Description",
      selector: (row: Unit) => row.description,
      sortable: true,
    },
    {
      id: "replaced",
      name: "Replaced",
      selector: (row: Unit) => row.replaced,
      sortable: true,
      omit: true,
    },
    {
      id: "belts",
      name: "Belts",
      selector: (row: Unit) => row.belts,
      sortable: true,
      omit: true,
    },
    {
      id: "filters",
      name: "Filters",
      selector: (row: Unit) => row.filters,
      sortable: true,
      omit: true,
    },
    {
      id: "voltage",
      name: "Voltage",
      selector: (row: Unit) => row.voltage,
      sortable: true,
    },
    {
      id: "contract",
      name: "Contract",
      selector: (row: Unit) => row.contract,
      sortable: true,
    },
    {
      id: "notes",
      name: "Notes",
      selector: (row: Unit) => row.notes,
      omit: true,
    },
  ];

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleSubmit = (data: Record<string, any>) => {
    console.log('data', data);
    fetchData().then();
    handleClose();
  };

  const handleUnitEdit = (unit: Unit) => {
    setSelectedUnit(unit);
    setShowOffCanvas(true);
  }
  const handleUnitClose = () => {
    setSelectedUnit(null);
    setShowOffCanvas(false);
  }
  const handleUnitSubmit = (data: Record<string, any>) => {
    console.log('data', data);
    fetchUnits().then();
    handleUnitClose();
  }

  return (
    <div>
      <Row className="mb-3">
        <Col className="col-lg-6 col-12 mb-2">
          <Card className="border-0">
            {buildingData ? (
              <Card.Body>
                <Card.Title className="text-dark">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>
                      <i className="fas fa-building me-2"></i>
                      {buildingData?.name}
                    </h5>
                    <h5>{buildingData?.building_id}</h5>
                  </div>
                </Card.Title>
                <Row>
                  <Col>
                    <span className={"d-block"}>{buildingData?.address}</span>
                    <span className={"d-block"}>{buildingData?.city}</span>
                    <span className={"d-block"}>{buildingData?.state}</span>
                    <span className={"d-block"}>{buildingData?.zip}</span>
                  </Col>
                  <Col className="text-end">
                    <div className="d-flex flex-column gap-2 justify-content-start">
                      <span>
                        Property Manager:{" "}
                        {buildingData?.property_manager?.first_name}{" "}
                        {buildingData?.property_manager?.last_name}
                      </span>
                      <span>
                        Technician: {buildingData?.technician?.first_name}{" "}
                        {buildingData?.technician?.last_name}
                      </span>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => openTabAndScroll("units")}
                    >
                      Units
                    </Button>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => openTabAndScroll("tenants")}
                    >
                      Tenants
                    </Button>
                  </div>
                  <div className="d-flex justify-content-end align-items-center gap-2">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={handleShow}
                    >
                      Edit Property
                    </Button>
                  </div>
                </div>
              </Card.Body>
            ) : (
              <div className={"p-4"}>Loading...</div>
            )}
          </Card>
        </Col>
        <Col className="col-lg-6 col-12 mb-2" Style="min-height: 170px">
          <div className="h-100 w-100 overflow-hidden rounded-2">
            {buildingData && buildingData.address ? (
              <GoogleMapsComponent
                address={`${buildingData.address}, ${buildingData.city},
                         ${buildingData.state} ${buildingData.zip}`}
                building_name={buildingData.name}
              />
            ) : (
              <div>Loading Map...</div> // Or any other placeholder
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <h4 className="primary-dark-blue mb-0">Overview</h4>
          </div>
          <Tab.Container
            id="building-tabs"
            defaultActiveKey={activeKey}
            activeKey={activeKey}
          >
            {/*<Nav
              variant="pills"
              className="justify-content-start align-items-center mb-3 gap-3"
            >
              <div className="d-flex flex-rowjustify-content-end align-items-center">
                <Nav.Item>
                  <Nav.Link eventKey="units" className="py-1">
                    Units
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tenants" className="py-1">
                    Tenants
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>*/}
            <Row>
              {buildingData?.budgets
                .sort((a, b) => a.budget_year.localeCompare(b.budget_year))
                .map((budget) => (
                  <BudgetCard
                    key={budget.id}
                    budget={budget}
                    iconClass="fas fa-calendar-alt text-primary"
                  />
                ))}
            </Row>
            <Tab.Content ref={scrollToRef}>
              <Tab.Pane eventKey="units" className="w-100">
                <div className="border rounded-3 overflow-hidden">
                  <DataTable
                    title={unitsSubHeaderComponentMemo}
                    columns={unitColumns}
                    data={filteredUnitData}
                    pagination
                    highlightOnHover
                    responsive={true}
                    dense={true}
                    pointerOnHover
                    progressPending={pending}
                    progressComponent={<CustomLoader />}
                    customStyles={customStyles}
                    className="rounded-0"
                    paginationPerPage={25}
                    expandableRows={true}
                    expandableRowsComponent={ExpandedComponent}
                    expandOnRowClicked={false}
                    expandOnRowDoubleClicked={false}
                    expandableRowsHideExpander={false}
                    conditionalRowStyles={conditionalRowStyles}
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tenants" className="w-100">
                {id && <Tenants buildingId={id} />}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>

      <Offcanvas placement="start" show={showOffCanvas} onHide={handleUnitClose}>
        <Offcanvas.Header className={"bg-primary text-white"} closeButton closeVariant={"white"}>
          <Offcanvas.Title>Edit Unit</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DynamicForm config={unitsFormConfig} defaultValues={
            selectedUnit || {
              id: "",
              asset_code: "",
              building_id: "",
              location: "",
              manufacturer: "",
              model_number: "",
              serial_number: "",
              tenant_code: "",
              description: "",
              replaced: "",
              belts: "",
              filters: "",
              voltage: "",
              contract: "",
              notes: "",
            }
          }
          onSubmit={handleUnitSubmit} endpoint={
            "/units/" + selectedUnit?.id
          }
          method="PUT"
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton closeVariant={"white"} className={"bg-primary text-white"}>
          <Modal.Title>Edit Building</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DynamicForm
            config={buildingsFormConfig}
            defaultValues={
              buildingData || {
                building_id: "",
                name: "",
                address: "",
                city: "",
                state: "",
                zip: "",
              }
            }
            onSubmit={handleSubmit}
            endpoint={"/buildings/" + id}
            method="PUT"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BuildingDetails;
