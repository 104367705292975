import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useBreadcrumb } from "../../components/BreadcrumbContext";
import apiClient from "../../api/apiClient";
import {useNavigate, useParams} from 'react-router-dom';

interface Unit {
  "BldgId": string,
  "UnitCode": string,
  "sname": string,
  "TenantName": string,
  "TCode": string,
  "expir": string,
  "Contract": number,
  "Make": string,
  "Model": string,
  "SerialNumber": string,
  "Replaced": string,
  "Belts": string,
  "Filters": string,
  "sname1": string,
  "Notes": string,
  "Desc": string
}

const Units: React.FC = () => {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState<Unit[]>([]);
  const [filteredData, setFilteredData] = useState<Unit[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedState, setSelectedState] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const { setItems } = useBreadcrumb();
  const navigate = useNavigate();

  const { buildingId } = useParams<{ buildingId: string }>();
  const { unitCode } = useParams<{ unitCode: string }>();

  const customStyles = {
    headCells: {
      style: {
        borderRadius: '0 0 0 0',
        color: 'var(--primary-dark-blue)',
      },
    },
    cells: {
      style: {
        color: 'var(--primary-dark-blue)',
      },
    },
  };

  // Breadcrumb
  useEffect(() => {
    setItems([
      { name: "Home", path: "/dashboard", isActive: false },
      { name: "Buildings", path: "/buildings", isActive: false },
      { name: `${buildingId}`, path: `/building/${buildingId}`, isActive: false },
      { name: `${unitCode}`, path: `/building/${buildingId}/tenants`, isActive: false },
      { name: "Units", path: `${unitCode}`, isActive: true },
    ]);
  }, [setItems]);

  // Fetch Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get<Unit[]>("/building/" + buildingId + "/unit/" + unitCode + "/HvacUnits");
        if (Array.isArray(response.data)) {
          setData(response.data);
          setFilteredData(response.data);
          setPending(false);
        } else {
          console.error("Data is not an array", response.data);
          setError("Invalid data format");
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError("Failed to fetch data");
      }
    };

    fetchData();
  }, []);

  // Filter data based on search term and selected state
  useEffect(() => {
    const timeout = setTimeout(() => {
      const filtered = data.filter((Unit: Unit) => {
        return (
          searchTerm === "" ||
          Unit.sname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          Unit.TenantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          Unit.TCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
          Unit.Model.toLowerCase().includes(searchTerm.toLowerCase()) ||
          Unit.SerialNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          Unit.sname1.toLowerCase().includes(searchTerm.toLowerCase()) ||
          Unit.Notes.toLowerCase().includes(searchTerm.toLowerCase()) ||
          Unit.Desc.toLowerCase().includes(searchTerm.toLowerCase()) ||
          Unit.Make.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }, 200);
    return () => clearTimeout(timeout);
  }, [searchTerm, selectedState, data]);

  const CustomLoader = () => (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="p-4">
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between gap-3">
        <div className="d-flex align-items-center justify-content-start gap-3">
          <div className="custom-title fs-6">Unit List</div>
          <div>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input form-control form-control-sm"
            />
          </div>
        </div>
        <div>
        </div>
      </div>
    );
  }, [searchTerm, selectedState]);

  if (error) {
    return <div>{error}</div>;
  }

  if (pending) {
    return <CustomLoader />;
  }

  const columns = [
    {
      id: 'TenantName',
      name: 'Tenant Name',
      selector: (row: Unit) => row.TenantName,
      sortable: true,
    },
    {
      id: "sname",
      name: "Type",
      selector: (row: Unit) => row.sname,
      sortable: true,
    },
    {
      id: "Make",
      name: "Make",
      selector: (row: Unit) => row.Make,
      sortable: true,
    },
    {
      id: "Model",
      name: "Model",
      selector: (row: Unit) => row.Model,
      sortable: true,
    },
    {
      id: "SerialNumber",
      name: "Serial Number",
      selector: (row: Unit) => row.SerialNumber,
      sortable: true,
    },
    {
      id: "Belts",
      name: "Belts",
      selector: (row: Unit) => row.Belts,
      sortable: true,
    },
    {
      id: "Filters",
      name: "Filters",
      selector: (row: Unit) => row.Filters,
      sortable: true,
    },
    {
      id: "Desc",
      name: "Description",
      selector: (row: Unit) => row.Desc,
      sortable: true,
    },
    {
      id: "Notes",
      name: "Notes",
      selector: (row: Unit) => row.Notes,
      sortable: true,
    },
  ];

  const handleRowClicked = (row: Unit) => {
    //navigate(`/Unit/${row.Id}`);
  };

  return (
    <div className="border rounded-3 overflow-hidden">
      <DataTable
        title={subHeaderComponentMemo}
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover
        pointerOnHover
        progressPending={pending}
        progressComponent={<CustomLoader />}
        customStyles={customStyles}
        className="rounded-0"
        onRowClicked={handleRowClicked}
      />
    </div>
  );
};

export default Units;
