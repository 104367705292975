// src/index.tsx
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './msalInstance';
import 'bootstrap/dist/css/bootstrap.min.css';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </MsalProvider>
  </React.StrictMode>
);
