import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';
import { loginRequest } from '../../auth/msalConfig';
import { useAuth } from '../../auth/AuthContext'; // Import the useAuth hook

const AuthCallback: React.FC = () => {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const { fetchUser } = useAuth(); // Destructure the fetchUser method

  useEffect(() => {
    const fetchAccessToken = async () => {
      console.log("fetchAccessToken called");
      console.log("Accounts:", accounts);
      console.log("Interaction status:", inProgress);

      if (accounts.length > 0 && inProgress === InteractionStatus.None) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
          });
          const token = response.accessToken;
          console.log("Access token acquired:", token);

          localStorage.setItem('microsoft_token', token);
          console.log("Microsoft token saved to localStorage");

          const backendResponse = await axios.post(
            'https://merritt-api.roschweb.com/api/login',
            { token }
          );
          console.log("Backend response:", backendResponse.data);

          localStorage.setItem('backend_token', backendResponse.data.token);
          console.log("Backend token saved to localStorage");

          await fetchUser(); // Fetch user data after setting the backend token

          navigate('/dashboard');
          console.log("Navigating to /dashboard");
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            console.log("Interaction required, redirecting to acquire token");
            await instance.acquireTokenRedirect(loginRequest);
          } else {
            console.error("Failed to acquire token silently:", error);
          }
        }
      }
    };

    fetchAccessToken();
  }, [accounts, inProgress, instance, navigate, fetchUser]);

  return (
    <div className="d-flex justify-content-center align-items-center vh100 w-100">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default AuthCallback;
