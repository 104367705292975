import React from 'react';

interface SimpleLayoutProps {
  children: React.ReactNode;
}

const SimpleLayout: React.FC<SimpleLayoutProps> = ({ children }) => {
  return (
    <div className="auth-form p-3 w-100 m-auto">
      {children}
    </div>
  );
};

export default SimpleLayout;
