// src/auth/msalConfig.ts
import { Configuration } from '@azure/msal-browser';

const isLocalhost = window.location.hostname === 'localhost';

export const msalConfig: Configuration = {
  auth: {
    //clientId: 'e8f6cea3-11eb-4af5-9296-9a93beaabc78',
    clientId: '8defb1e0-fb00-42e3-a201-93a9c569ff50',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: isLocalhost
      ? 'http://localhost:3000/auth-callback'
      : 'https://merritt.roschweb.com/auth-callback', // Production URL
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['User.Read', 'User.ReadBasic.All'],
};
