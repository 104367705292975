// src/components/Breadcrumb.tsx
import React from "react";
import { Link } from "react-router-dom";
import { useBreadcrumb } from "../../components/BreadcrumbContext";

const Breadcrumb: React.FC = () => {
  const { items } = useBreadcrumb();

  return (
    <div className="bg-white shadow-sm border-bottom py-2 px-3">
      <nav aria-label="breadcrumb" className={"small"}>
        <ol className="breadcrumb my-0">
          {items.map((item, index) => (
            <li
              key={index}
              className={`breadcrumb-item ${item.isActive ? "active" : ""}`}
            >
              {item.isActive ? (
                <span>{item.name}</span>
              ) : (
                <Link to={item.path}>{item.name}</Link>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
