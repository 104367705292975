import React, { useEffect } from 'react';

const HeadElements: React.FC = () => {
  useEffect(() => {
    // Set the title
    document.title = 'Merritt Property Management Portal';

    // Create and append custom theme CSS link
    const themeCss = document.createElement('link');
    themeCss.rel = 'stylesheet';
    themeCss.href = '/css/theme.css';
    document.head.appendChild(themeCss);

    // Cleanup function to remove the elements when the component unmounts
    return () => {
      document.head.removeChild(themeCss);
    };
  }, []);

  return null;
};

export default HeadElements;
