import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Modal } from 'react-bootstrap';
import apiClient from '../api/apiClient';

interface FieldConfig {
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  validation?: object;
  message?: string;
  className?: string;
  options?: { value: string; label: string }[];
}

interface FormConfig {
  title: string;
  fields: FieldConfig[];
}

interface DynamicFormProps {
  config: FormConfig;
  defaultValues: Record<string, any>;
  onSubmit: (data: Record<string, any>) => void;
  endpoint: string;
  method?: 'POST' | 'PUT' | 'PATCH';
}

const DynamicForm: React.FC<DynamicFormProps> = ({ config, defaultValues, onSubmit, endpoint, method = 'post' }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues });

  const handleFormSubmit = async (data: Record<string, any>) => {
    try {
      let response;
      if (method === 'PUT') {
        response = await apiClient.put(endpoint, data);
      } else {
        response = await apiClient.post(endpoint, data);
      }
      //console.log('Response:', response);
      onSubmit(response.data); // Optional: Callback if you need to do something in parent component
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const renderField = (field: FieldConfig) => {
    switch (field.type) {
      case 'select':
        return (
          <Form.Group controlId={`control_${field.name}`} key={field.name} className="mb-3">
            <Form.Label className="mb-1">{field.label}</Form.Label>
            <Form.Control as="select" {...register(field.name, field.validation)} isInvalid={!!errors[field.name]}>
              {field.options?.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {field.message || ''}
            </Form.Control.Feedback>
          </Form.Group>
        );
      case 'textarea':
        return (
          <Form.Group controlId={`control_${field.name}`} key={field.name} className="mb-3">
            <Form.Label className="mb-1">{field.label}</Form.Label>
            <Form.Control as="textarea" {...register(field.name, field.validation)} isInvalid={!!errors[field.name]} placeholder={field.placeholder} />
            <Form.Control.Feedback type="invalid">
              {field.message || ''}
            </Form.Control.Feedback>
          </Form.Group>
        );
      case 'checkbox':
        return (
          <Form.Group controlId={`control_${field.name}`} key={field.name} className="mb-3">
            <Form.Check
              type="checkbox"
              label={field.label}
              {...register(field.name, field.validation)}
              isInvalid={!!errors[field.name]}
            />
          </Form.Group>
        );
      case 'radio':
        return (
          <Form.Group controlId={`control_${field.name}`} key={field.name} className="mb-3">
            <Form.Label className="mb-1">{field.label}</Form.Label>
            {field.options?.map(option => (
              <Form.Check
                key={option.value}
                type="radio"
                label={option.label}
                value={option.value}
                {...register(field.name, field.validation)}
                isInvalid={!!errors[field.name]}
              />
            ))}
          </Form.Group>
        );
      default:
        return (
          <Form.Group controlId={`control_${field.name}`} key={field.name} className="mb-3">
            <Form.Label className="mb-1">{field.label}</Form.Label>
            <Form.Control
              type={field.type}
              placeholder={field.placeholder}
              {...register(field.name, field.validation)}
              isInvalid={!!errors[field.name]}
              className={field.className || ''}
            />
            <Form.Control.Feedback type="invalid">
              {field.message || ''}
            </Form.Control.Feedback>
          </Form.Group>
        );
    }
  };


  return (
    <Form onSubmit={handleSubmit(handleFormSubmit)}>
      {config.fields.map(field => renderField(field))}
      <div className={'d-flex justify-content-end'}>
        <Button type="submit" variant="primary">Save</Button>
      </div>
    </Form>
  );
};

export default DynamicForm;
