// Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="footer d-flex justify-content-between align-items-center p-2">
      <div className="px-4">
        <a href="https://roschweb.com">Merritt Admin
        Template</a> &copy; 2024
      </div>
      <div className="px-4">Powered by&nbsp;<a href="https://roschweb.com">Rosch Web Solutions</a></div>
    </footer>
  );
};

export default Footer;
