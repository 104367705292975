// ToastContext.tsx
import React, { createContext, useContext, useState, ReactNode, FC } from 'react';
import { Toast } from 'react-bootstrap';

type ToastContextType = {
  triggerToast: (msg: string) => void;
};

const ToastContext = createContext<ToastContextType | null>(null);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) throw new Error("useToast must be used within a ToastProvider");
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const triggerToast = (msg: string) => {
    setMessage(msg);
    setShowToast(true);
  };

  return (
    <ToastContext.Provider value={{ triggerToast }}>
      {children}
      <Toast className="top-0 start-50 translate-middle-x position-absolute mt-5"
             onClose={() => setShowToast(false)} show={showToast} delay={30000} autohide>
        <Toast.Header className="bg-warning bg-opacity-25">
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContext.Provider>
  );
};
