import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/msalConfig";
import usePageClasses from "../../hooks/usePageClasses";

const Login: React.FC = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  usePageClasses("bg-light", "auth-full-page d-flex vh-100");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError("");
    try {
      await login(email, password);
      navigate("/dashboard");
    } catch (error) {
      setError("Invalid email or password");
    }
  };

  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((error) => {
      console.error(error);
    });
  };

  return (
    <div>
      <div className="text-center">
        <img src="/images/logo-header-dark.png" alt="logo" className="mb-3" />
        <p className="fs-6 fw-light">Sign in to your account to continue</p>
      </div>

      <div className="mb-3">
        <div className="d-grid gap-2 mb-3">
          <button
            onClick={handleLogin}
            className="btn btn-google shadow-sm position-relative bg-white"
          >
            <i
              className="fab fa-fw fa-microsoft fa-microsoft-colored position-absolute top-50 translate-middle-y"
              aria-hidden="true"
            ></i>
            <span className="mx-auto w-100 small">Login with Microsoft</span>
          </button>
        </div>
        <div className="row">
          <div className="col">
            <hr />
          </div>
          <div className="col-auto text-uppercase d-flex align-items-center">
            Or
          </div>
          <div className="col">
            <hr />
          </div>
        </div>
        <form id="loginForm" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="form-control form-control-lg"
              placeholder="Enter your email"
              autoComplete={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="password">
              Password
            </label>
            <input
              className="form-control form-control-lg"
              type="password"
              id="password"
              value={password}
              autoComplete={"current-password"}
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <small>
              <a href="/auth-reset-password" className="text-decoration-none">
                Forgot password?
              </a>
            </small>
          </div>
          <div>
            <div className="form-check align-items-center">
              <input
                id="customControlInline"
                type="checkbox"
                className="form-check-input"
                value="remember-me"
                name="remember-me"
              />
              <label
                className="form-check-label text-small"
                htmlFor="customControlInline"
              >
                Remember me
              </label>
            </div>
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="d-grid gap-2 mt-3">
            <button className="btn btn-sm btn-primary py-2" type="submit">
              Sign in
            </button>
          </div>
        </form>
      </div>

      <div className="text-center">
        Don't have an account?{" "}
        <a href="/register" className="text-decoration-none">
          Sign up
        </a>
      </div>
    </div>
  );
};

export default Login;
