import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useBreadcrumb } from "../../components/BreadcrumbContext";
import apiClient from "../../api/apiClient";
import { useNavigate } from 'react-router-dom';

interface Tenant {
  BldgId: string;
  Unit: string;
  FLOORID: string;
  Name: string;
  Id: string;
  Address: string;
  StartDate: string;
  EndDate: string;
  UnitSqFt: number;
  istatus: number;
}

interface TenantsProps {
  buildingId: string; // Add buildingId as a prop
}

const Tenants: React.FC<TenantsProps> = ({ buildingId }) => {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState<Tenant[]>([]);
  const [filteredData, setFilteredData] = useState<Tenant[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { setItems } = useBreadcrumb();
  const navigate = useNavigate();

  const customStyles = {
    headCells: {
      style: {
        borderRadius: '0 0 0 0',
        color: 'var(--primary-dark-blue)',
      },
    },
    cells: {
      style: {
        color: 'var(--primary-dark-blue)',
      },
    },
  };

  // Breadcrumb
  useEffect(() => {
    if (buildingId) {
      setItems([
        { name: "Home", path: "/dashboard", isActive: false },
        { name: "Buildings", path: "/buildings", isActive: false },
        { name: `${buildingId}`, path: `/building/${buildingId}`, isActive: false },
        { name: "Tenants", path: `/building/${buildingId}/tenants`, isActive: true },
      ]);
    }
  }, [setItems, buildingId]);

  // Fetch Data
  useEffect(() => {
    if (buildingId) {
      const fetchData = async () => {
        try {
          const response = await apiClient.get<Tenant[]>(`/building/${buildingId}/tenants`);
          if (Array.isArray(response.data)) {
            setData(response.data);
            setFilteredData(response.data);
            setPending(false);
          } else {
            console.error("Data is not an array", response.data);
            setError("Invalid data format");
          }
        } catch (err) {
          console.error('Error fetching data:', err);
          setError("Failed to fetch data");
        }
      };

      fetchData();
    }
  }, [buildingId]);

  // Filter data based on search term
  useEffect(() => {
    const timeout = setTimeout(() => {
      const filtered = data.filter((tenant: Tenant) => {
        return (
          searchTerm === "" ||
          tenant.Unit.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tenant.FLOORID.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tenant.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tenant.Address.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }, 200);
    return () => clearTimeout(timeout);
  }, [searchTerm, data]);

  const CustomLoader = () => (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="p-4">
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between gap-3">
        <div className="d-flex align-items-center justify-content-start gap-3">
          <div className="custom-title fs-6">Tenant List</div>
          <div>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input form-control form-control-sm"
            />
          </div>
        </div>
        <div>
        </div>
      </div>
    );
  }, [searchTerm]);

  if (error) {
    return <div>{error}</div>;
  }

  if (pending) {
    return <CustomLoader />;
  }

  const columns = [
    {
      id: 'Id',
      name: 'Tenant ID',
      selector: (row: Tenant) => row.Id,
      sortable: true,
    },
    {
      id: "Name",
      name: "Name",
      selector: (row: Tenant) => row.Name,
      sortable: true,
    },
    {
      id: "Address",
      name: "Address",
      selector: (row: Tenant) => row.Address,
      sortable: true,
    },
    {
      id: "Unit",
      name: "Unit",
      selector: (row: Tenant) => row.Unit,
      sortable: true,
    },
    {
      id: "UnitSqFt",
      name: "UnitSqFt",
      selector: (row: Tenant) => row.UnitSqFt,
      sortable: true,
    },
  ];

  const handleRowClicked = (row: Tenant) => {
    const bldgId = row.BldgId.trim();
    const unit = row.Unit.trim();
    navigate(`/building/${bldgId}/unit/${unit}/hvac-units`);
  };

  return (
    <div className="border rounded-3 overflow-hidden">
      <DataTable
        title={subHeaderComponentMemo}
        columns={columns}
        data={filteredData}
        responsive={true}
        dense={true}
        pagination
        highlightOnHover
        pointerOnHover
        progressPending={pending}
        customStyles={customStyles}
        className="rounded-0"
        onRowClicked={handleRowClicked}
      />
    </div>
  );
};

export default Tenants;
