import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import AuthCallback from "./pages/AuthCallback/AuthCallback";
import Dashboard from "./pages/Dashboard/Dashboard";
import PrivateRoute from "./components/PrivateRoute";
import HeadElements from "./components/HeadElements";
import SimpleLayout from './layout/SimpleLayout/SimpleLayout';
import MainLayout from './layout/MainLayout/MainLayout';
import Buildings from './pages/Buildings/Buildings';
import BuildingDetails from './pages/Buildings/BuildingDetails';
import Units from './pages/Units/Units';
import Logout from './pages/Logout/Logout';
import IdleTimerComponent from './components/IdleTimerComponent';
import { ToastProvider } from './contexts/ToastContext';
import Tools from './pages/Tools/Tools';
import PMSheet from './pages/PMSheet/PMSheet';

const App: React.FC = () => {
  return (
    <ToastProvider>
      <HeadElements />
      <IdleTimerComponent />
      <Routes>
        <Route path="/register" element={<SimpleLayout><Register /></SimpleLayout>} />
        <Route path="/login" element={<SimpleLayout><Login /></SimpleLayout>} />
        <Route path="/auth-callback" element={<SimpleLayout><AuthCallback /></SimpleLayout>} />
        <Route path="/" element={<SimpleLayout><Login /></SimpleLayout>} />
        <Route
          path="/logout"
          element={
            <PrivateRoute>
              <MainLayout><Logout /></MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <MainLayout><Dashboard /></MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/buildings"
          element={
            <PrivateRoute>
              <MainLayout><Buildings /></MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/building/:id"
          element={
            <PrivateRoute>
              <MainLayout><BuildingDetails /></MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/building/:buildingId/unit/:unitCode/hvac-units"
          element={
            <PrivateRoute>
              <MainLayout><Units /></MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/pmsheet"
          element={
            <PrivateRoute>
              <MainLayout><PMSheet /></MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/tools"
          element={
            <PrivateRoute>
              <MainLayout><Tools /></MainLayout>
            </PrivateRoute>
          }
        />
      </Routes>
    </ToastProvider>
  );
};

export default App;
