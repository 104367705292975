/*
interface Unit {
  id: number;
  asset_code: string;
  building_id: string;
  location: string;
  manufacturer: string;
  model_number: string;
  serial_number: string;
  tenant_code: string;
  description: string;
  replaced: string;
  belts: string;
  filters: string;
  voltage: string;
  contract: string;
  notes: string;
}
 */
const unitsFormConfig = {
  title: "Edit Unit",
  fields: [
    {
      name: "building_id",
      label: "Building ID",
      type: "text",
      placeholder: "Enter building ID",
      validation: { required: "Building ID is required" },
      className: "form-control-sm",
    },

    {
      name: "asset_code",
      label: "Asset Code",
      type: "text",
      placeholder: "Enter asset code",
      validation: { required: "Asset code is required" },
      className: "form-control-sm",
    },
    {
      name: "location",
      label: "Location",
      type: "text",
      placeholder: "Enter location",
      validation: { required: "Location is required" },
      className: "form-control-sm",
    },
    {
      name: "manufacturer",
      label: "Manufacturer",
      type: "text",
      placeholder: "Enter manufacturer",
      validation: {},
      className: "form-control-sm",
    },
    {
      name: "model_number",
      label: "Model Number",
      type: "text",
      placeholder: "Enter model number",
      validation: {},
      className: "form-control-sm",
    },
    {
      name: "serial_number",
      label: "Serial Number",
      type: "text",
      placeholder: "Enter serial number",
      validation: {},
      className: "form-control-sm",
    },
    {
      name: "tenant_code",
      label: "Tenant Code",
      type: "text",
      placeholder: "Enter tenant code",
      validation: {},
      className: "form-control-sm",
    },
    {
      name: "description",
      label: "Description",
      type: "text",
      placeholder: "Enter description",
      validation: {},
      className: "form-control-sm",
    },
    {
      name: "replaced",
      label: "Replaced",
      type: "text",
      placeholder: "Enter replaced",
      validation: {},
      className: "form-control-sm",
    },
    {
      name: "belts",
      label: "Belts",
      type: "text",
      placeholder: "Enter belts",
      validation: {},
      className: "form-control-sm",
    },
    {
      name: "filters",
      label: "Filters",
      type: "text",
      placeholder: "Enter filters",
      validation: {},
      className: "form-control-sm",
    },
    {
      name: "voltage",
      label: "Voltage",
      type: "text",
      placeholder: "Enter voltage",
      validation: {},
      className: "form-control-sm",
    },
    {
      name: "contract",
      label: "Contract",
      type: "text",
      placeholder: "Enter contract",
      validation: {},
      className: "form-control-sm",
    },
    {
      name: "notes",
      label: "Notes",
      type: "textarea",
      placeholder: "Enter notes",
      validation: {},
      className: "form-control-sm",
    },
  ]
};

export default unitsFormConfig;
