// Header.tsx
import React, { useEffect, useState } from 'react';
import { refreshToken } from '../../auth/tokenUtils';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  isOpen: boolean;
  handleClick: () => void;
}

interface User {
  id: number;
  name: string;
  email: string;
}

const Header: React.FC<HeaderProps> = ({ isOpen, handleClick }) => {
  const [photo, setPhoto] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfilePhoto = async (token: string) => {
      try {
        const photoResponse = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!photoResponse.ok) {
          throw new Error('Failed to fetch profile photo');
        }

        const photoBlob = await photoResponse.blob();
        const photoUrl = URL.createObjectURL(photoBlob);
        setPhoto(photoUrl);
      } catch (err) {
        setError('Failed to fetch profile photo');
        // If failed to fetch, log the user out
        navigate('/logout');
        console.error(err);
      }
    };

    const getTokenAndFetchPhoto = async () => {
      let token = localStorage.getItem('microsoft_token');
      if (!token) {
        token = await refreshToken();
        if (!token) {
          setError('No access token found and failed to refresh token');
          return;
        }
      }

      await fetchProfilePhoto(token);
    };

    getTokenAndFetchPhoto().then();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  return (
    <header
      className={`header sticky-top bg-white border-bottom ${isOpen ? 'body-pd' : ''}`}
      id="header"
    >
      <div className="header_container d-flex flex-row align-items-center p-3 gap-2">
        <div className="header_logo">
          <img src="/images/logo-header-dark.png" alt="logo" />
        </div>
        <div className="header_nav d-flex gap-2 align-items-center justify-content-between">
          <div className="header_toggle pointer" onClick={handleClick}>
            <i
              className={`fa fa-2x ${isOpen ? 'fa-bars-staggered' : 'fa-bars'}`}
              id="header-toggle"
            ></i>
          </div>
          <div className="d-flex flex-row gap-2 justify-content-end align-items-center">
            <div className="user_name">
              <span>{user?.name}</span>
            </div>
            <div className="header_img">
              {photo ? (
                <img src={photo} alt="It's me, Hi!" className="rounded-circle" />
              ) : (
                <i className="fa fa-user-circle fa-2x"></i>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
